import axios from 'axios';

export const customHeaders = {
    'Content-Type': 'application/json',
    Accept: '*',
};

// export const baseUrl = 'http://localhost:8000/mythaiadviser/api/';
// export const baseApi = 'http://localhost:8000/mythaiadviser/api/?/';

// const Url = "https://api.mythaiadvisor.com/";
// export const baseUrl = Url;
// export const baseApi = Url;

export const baseUrl = 'https://api-ci.mythaiadvisor.com/';
export const baseApi = 'https://api.mythaiadvisor.com/';

export const secret = `d40e9f9570f67354601c2db7ed70f582`;

export default axios.create({
    baseUrl,
    headers: customHeaders
});